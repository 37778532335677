import './cards.less';

import * as ko from 'knockout';
import template from './cards.html';

export class CardsViewModel {

  public cardList: any;
  public activeCard: ko.PureComputed<any> = ko.pureComputed<any>(() => {
    return this.cardList().find((x: any) => x.active);
  })
  public activeCardTitle = ko.pureComputed(() => this.activeCard() && this.activeCard().title)
  public menuOpen = ko.observable(false);

  constructor(params: any) {
    console.log(params);
    this.cardList = params.cardList;
  }

  public toggleMenu() {
    this.menuOpen(!this.menuOpen());
  }

  dispose() {
  }
}

ko.components.register("cards", { viewModel: CardsViewModel, template: template })

