import './notif.less';

import * as ko from 'knockout';
import template from './notif.html';
import { WorkerType, WorkerWrapper } from '../../services/workers/workerWrapper';
import { swReg } from "../../app";
export class NotifViewModel {

  public title = ko.observable("Testtitle");
  public body = ko.observable("Default content");
  public waitTime = ko.observable(0);

  constructor(params: any) {
  }

  scheduleNotification() {

    Notification.requestPermission().then(x => {
      if (x == "granted") {
        const notificationOptions = <NotificationOptions>{
          body: this.body(),
          actions: [
            { action: "a1", title: "Important option 1" },
            { action: "a2", title: "Another option 2" }
          ]
        }
        swReg.active.postMessage({ action: "notification", text: this.title(), timeout: this.waitTime(), options: notificationOptions })
      }
    })
  }

  dispose() {
  }
}

ko.components.register("notif", { viewModel: NotifViewModel, template: template })

