import * as ko from "knockout";

class LocationService {
    public supported = ko.observable(false);
    private default: Position = {
        coords: {
            accuracy: 0,
            altitude: 0,
            altitudeAccuracy: 0,
            heading: 0,
            latitude: 0,
            longitude: 0,
            speed: 0
        },
        timestamp: 0
    };
    public geolocationData = ko.observable<Position>(this.default);
    public geolocationWatcher = ko.computed(() => {
        if (!this.supported()) return "Not supported";
    });

    constructor() {
        this.initGeolocation();
    }


    private initGeolocation() {
        this.supported('geolocation' in navigator);
    }

    public readLocation() {
        if (this.supported()) {
            navigator.geolocation.getCurrentPosition(position => {
                this.geolocationData(position);
            }, () => this.geolocationData(this.default));
        }
    }
}

export const service = new LocationService();