import "./fetch.worker";
import * as fetchWorkerPath from "file-loader?name=[name].js!./fetch.worker";

export enum WorkerType {
    unknown = 0, fetch = 1
}

export class WorkerWrapper {
    private worker: Worker;

    private constructor() { }

    private getWorker(type: WorkerType) {
        switch (type) {
            case WorkerType.fetch:
                return this.worker = new Worker(fetchWorkerPath);
            case WorkerType.unknown:
            default:
                throw new Error("Unknown worker type!");
        }
    }

    private terminate() { if (this.worker) this.worker.terminate(); }

    public static async postToWorker<TResult, TInput>(type: WorkerType, data: TInput): Promise<TResult> {
        const workerWrapper = new WorkerWrapper();
        return new Promise<TResult>((res, rej) => {
            try {
                const worker = workerWrapper.getWorker(type);
                worker.onmessage = (ev) => res(ev.data);
                worker.onerror = (ev) => rej(ev.message);
                worker.postMessage(data);
            } catch (e) {
                rej(e);
            }
        }).finally(() => workerWrapper.terminate());
    }
}