import './map.less';

import * as ko from 'knockout';
import template from './map.html';
import { service as locationService } from "../../services/locationService";

interface IPOI {
  name: string;
  long: number;
  lat: number;
}

export class MapViewModel {

  private map: any = null;
  public longitude = ko.observable<number>(-1);
  public latitude = ko.observable<number>(-1);
  public valueChanged = ko.observable(false);
  public pointsOfInterests = ko.observableArray<IPOI>([]);
  public managePOIs = ko.observable(false);
  public addPOIOnClick = ko.observable(false);
  private markersLayer = new (<any>window).L.LayerGroup();

  public mapUpdater = ko.computed(() => {
    const data = locationService.geolocationData();

    if (!this.valueChanged()) {
      this.longitude(data.coords.longitude);
      this.latitude(data.coords.latitude);
    } else this.valueChanged(false);

    if (this.map)
      this.map.setView([this.latitude(), this.longitude()], 13)
  })

  constructor(params: any) {
    this.setupValueUpdated();
    this.createUpdateMap();
  }

  private setupValueUpdated() {
    this.longitude.subscribe(() => this.valueChanged(true));
    this.latitude.subscribe(() => this.valueChanged(true));
  }

  private createUpdateMap() {
    if (locationService.supported())
      locationService.readLocation();

    if (!!this.map) return;

    this.map = (<any>window).L.map('map').setView([0, 0], 5);
    (<any>window).L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw', {
      maxZoom: 18,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
    }).addTo(this.map);
    this.markersLayer.addTo(this.map);

    this.longitude(0);
    this.latitude(0);
    this.loadPOIs();
  }

  public showHidePOIs() {
    if (this.managePOIs()) {
      this.drawPOIs();
    }
    this.managePOIs(!this.managePOIs());
  }

  private drawPOIs() {
    this.markersLayer.clearLayers();

    this.storePOIs();

    const pois = this.pointsOfInterests();
    for (const entry of this.pointsOfInterests()) {
      const marker = new (<any>window).L.marker([+entry.lat, +entry.long])
        .bindPopup(entry.name)
        .addTo(this.markersLayer);
    }
  }

  private storePOIs() {
    localStorage.setItem('pointsOfInterest', ko.toJSON(this.pointsOfInterests()));
  }

  private loadPOIs() {
    console.log(123);
    this.pointsOfInterests(JSON.parse(localStorage.getItem('pointsOfInterest')) ?? []);
    if (this.pointsOfInterests().length > 0)
      this.drawPOIs();
  }

  public enableAddOnClick() {
    this.addPOIOnClick(!this.addPOIOnClick());
    if (this.addPOIOnClick()) {
      this.map.on("click", (e: any) => {
        this.pointsOfInterests.push({ name: "Point of Interest " + (this.pointsOfInterests().length + 1), lat: e.latlng.lat, long: e.latlng.lng });
        this.drawPOIs();
      });
    } else {
      this.map.off("click");
    }

  }

  public addPOI() {
    this.pointsOfInterests.push({ name: "", lat: 0, long: 0 });
  }

  public deletePOI(entry: IPOI) {
    this.pointsOfInterests.remove(entry);
  }

  public loadCurrentLocation() {
    if (locationService.supported())
      locationService.readLocation();
  }

  dispose() {
  }
}

ko.components.register("poimap", { viewModel: MapViewModel, template: template })

