import './shooter.less';

import * as ko from 'knockout';
import template from './shooter.html';

import * as game from "./game";

export class CardsViewModel {
  public canvas2DStarted = ko.observable(false);
  public game: game.Game;

  constructor() {
  }

  startCanvas2D() {
    this.canvas2DStarted(true);
    this.game = new game.Game();
    this.game.Run();
  }
  stopCanvas2D() {
    this.game.Stop();
    this.game = null;
    this.canvas2DStarted(false);
  }

  dispose() {
  }
}

ko.components.register("shooter", { viewModel: CardsViewModel, template: template })

