import * as ko from "knockout";

interface IDeviceOrientationData {
    ltrTilt: number;
    ftbTilt: number;
    direction: number;
}

class DeviceOrientationService {
    public data = ko.observable<IDeviceOrientationData>({ ltrTilt: 0, ftbTilt: 0, direction: 0 });
    public readSensors = ko.observable(false);
    public supported = ko.observable(false);
    constructor() {
        this.initHandler();
    }

    private initHandler() {
        if ("DeviceOrientationEvent" in window) {
            this.supported(true);
            window.addEventListener('deviceorientation', event => {
                if (this.readSensors())
                    this.data({ ftbTilt: event.beta, ltrTilt: event.gamma, direction: event.alpha });
            });
        }
    }
}
export const service = new DeviceOrientationService();