import './click-counter.less';

import * as ko from 'knockout';
import template from './click-counter.html';

export class ClickCounterViewModel {
  public count = ko.observable(0);
  public increment = () => {
    this.count(this.count() + 1);
  }

  constructor() {
  }

  dispose() {
  }
}

ko.components.register("click-counter", { viewModel: ClickCounterViewModel, template: template })

