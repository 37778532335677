const runtime = require('serviceworker-webpack-plugin/lib/runtime');
let swReg: any;
if ('serviceWorker' in navigator) {
  runtime.register().then((reg: any) => {
    swReg = reg;
    reg.addEventListener('updatefound', () => {
      var newWorker = reg.installing;
      newWorker.addEventListener('statechange', () => {
        switch (newWorker.state) {
          case 'installed':
            if (navigator.serviceWorker.controller) {
              // alert("New version! Updating now!");
              newWorker.postMessage({ action: 'skipWaiting' });
            }
            break;
        }
      });
    });
  });

  let refreshing = false;
  navigator.serviceWorker.addEventListener('controllerchange', function () {
    if (refreshing) return;
    window.location.reload();
    refreshing = true;
  });

}

export { swReg }