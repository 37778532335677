class GameObject {
    constructor(gameContext, drawContext, x, y, vx, vy) {
        this.gameContext = gameContext;
        this.context = drawContext;
        this.x = x;
        this.y = y;
        this.vx = vx;
        this.vy = vy;
        this.isColliding = false;
    }

    IsVisible() {
        return !(0 > (this.x + this.context.canvas.width) || (this.context.canvas.width) < this.x || 0 > (this.y + this.context.canvas.height) || (this.context.canvas.height) < this.y)
    }
}

class Square extends GameObject {

    constructor(gameContext, drawContext, x, y, vx, vy) {
        super(gameContext, drawContext, x, y, vx, vy);

        this.width = 5;
        this.height = 5;
        this.BaseColor = "#000000".replace(/0/g, function () { return (~~(Math.random() * 16)).toString(16); }); //'#ff8080';
    }

    Update(timePassed) {
        this.x += (this.vx * timePassed);
        this.y += (this.vy * timePassed);
    }

    Draw() {
        this.context.fillStyle = this.BaseColor;
        this.context.fillRect(this.x, this.y, this.width, this.height);
    }
}

class Player extends GameObject {

    constructor(gameContext, drawContext, x, y, vx, vy) {
        super(gameContext, drawContext, x, y, vx, vy);

        this.width = 10;
        this.height = 30;
        this.Speed = 100;
        this.Shooting = false;
        this.ShotSpeed = 100;
        this.Shots = [];

        this.BaseColor = "#000000".replace(/0/g, function () { return (~~(Math.random() * 16)).toString(16); }); //'#ff8080';

        this.Init();
    }

    Init() {
        this.HandleMovement();
        this.HandleShooting();
    }

    HandleShooting() {
        setTimeout(() => {
            this.FireShot(Math.random() * (window.innerWidth / 2), Math.random() * (window.innerWidth / 16 * 9));
            this.HandleShooting();
        }, 50);
        // this.gameContext.canvas.addEventListener("click", (ev) => this.FireShot(ev));
    }

    FireShot(ev) {
        const angle = Math.atan2(ev.clientY - this.y, ev.clientX - this.x);
        this.SpawnShot(angle);
    }

    FireShot(y, x) {
        this.SpawnShot(Math.random() * 6);
    }

    SpawnShot(angle) {
        const shot = new Square(this.gameContext, this.context, this.x, this.y + (this.height / 2) - 2.5, Math.cos(angle) * this.ShotSpeed, Math.sin(angle) * this.ShotSpeed)
        this.Shots.push(shot);
    }

    GetShotDirection(x1, y1, x2, y2) {
        // might be negative:
        var angle = Math.atan2(y2 - y1, x2 - x1) * 180 / Math.PI;
        // correct, positive angle:
        return (angle + 360) % 360;
    }

    HandleMovement(player) {
        this.left = false, this.right = false, this.up = false, this.down = false;

        document.body.addEventListener('keydown', (event) => { handleKey(event.keyCode, true); })
        document.body.addEventListener('keyup', (event) => { handleKey(event.keyCode, false); })

        const handleKey = (key, upDown) => {
            switch (key) {
                case 37: case 65: this.left = upDown; break; //Left
                case 38: case 87: this.up = upDown; break; //Up
                case 39: case 68: this.right = upDown; break; //Right
                case 40: case 83: this.down = upDown; break; //Down
                default: break;
            }
        }
    }

    Update(timePassed) {
        this.UpdatePosition(timePassed);
        this.UpdateShots(timePassed);
    }

    UpdateShots(timePassed) {
        this.Shots.forEach(x => x.Update(timePassed));

        const toRemove = [];
        for (let i = 0; i < this.Shots.length; i++) {
            if (!this.Shots[i].IsVisible())
                toRemove.push(this.Shots[i]);
        }

        for (let i = 0; i < toRemove.length; i++) {
            this.Shots.splice(this.Shots.indexOf(toRemove[i]), 1);
        }
    }

    UpdatePosition(timePassed) {
        if (this.left) this.vx = this.Speed * -1;
        else if (this.right) this.vx = this.Speed;
        else this.vx = 0;
        if (this.up) this.vy = this.Speed * -1;
        else if (this.down) this.vy = this.Speed;
        else this.vy = 0;

        this.x += (this.vx * timePassed);
        this.y += (this.vy * timePassed);
    }

    Draw() {
        this.context.fillStyle = this.BaseColor;
        this.context.fillRect(this.x, this.y, this.width, this.height);

        this.Shots.forEach(x => x.Draw());
    }
}

class Game {

    constructor(width, height) {
        this.CanvasDiv = document.getElementById("shooter__canvas");

        this.running = true;
        this.Width = this.CanvasDiv.clientWidth;
        this.Height =this.CanvasDiv.clientHeight;
        this.Init();
        this.GameObjects = [];
        this.Canvases = [];

        this.DefaultContext = this.GetCreateContext(0).context;

        this.Player = null;
        this.SetupPlayer(this.DefaultContext)

        this.GameObjects.sort((a, b) => { return (a.BaseColor).localeCompare(b.BaseColor); });
    }

    SetupPlayer(context) {
        this.Player = this.SpawnPlayer(this.DefaultContext);
    }

    SpawnPlayer(context) {
        const player = new Player(this.Context, context, this.Width / 2, this.Height / 2, 0, 0);
        this.GameObjects.push(player);
        return player;
    }

    SpawnParticle(context) {
        const x = Math.floor(Math.random() * this.Width);
        const y = Math.floor(Math.random() * this.Height);
        const vx = Math.floor(Math.random() * 101);
        const vy = Math.floor(Math.random() * 101);
        const vxSign = Math.random() < 0.5;
        const vySign = Math.random() < 0.5;
        this.GameObjects.push(new Square(context, x, y, vx * (vxSign ? 1 : -1), vy * (vySign ? 1 : -1)));
    }

    GetCreateContext(rest) {
        if (rest != 0)
            return this.Canvases[this.Canvases.length - 1];

        const m_canvas = document.createElement('canvas');
        m_canvas.width = this.Width;
        m_canvas.height = this.Height;
        const m_context = m_canvas.getContext("2d");

        this.Canvases.push({ canvas: m_canvas, context: m_context });
        return { canvas: m_canvas, context: m_context };
    }

    /*public*/
    Run() {
        //Starts the Game Loop
        requestAnimationFrame((newTimeStamp) => this.Loop(newTimeStamp));
    }

    /*Private*/
    Init() {
        this.IsRunning = false;
        this.OldTimeStamp = 0;
        this.SetupCanvas();
        this.FPSCounter = document.getElementById("fpsCounter");
    }


    Loop(timeStamp) {
        if (!this.running) return;
        const secondsPassed = (timeStamp - this.OldTimeStamp) / 1000;

        this.DrawFPS(secondsPassed);

        this.Update(secondsPassed);
        this.Draw();

        this.OldTimeStamp = timeStamp;
        requestAnimationFrame((newTimeStamp) => this.Loop(newTimeStamp));
    }

    Stop() {
        this.running = false;
    }

    Update(secondsPassed) {
        this.GameObjects.forEach(x => x.Update(secondsPassed));

        const toRemove = [];
        for (let i = 0; i < this.GameObjects.length; i++) {
            if (!this.GameObjects[i].IsVisible())
                toRemove.push(this.GameObjects[i]);
        }

        for (let i = 0; i < toRemove.length; i++) {
            if (this.Respawn)
                this.SpawnParticle(toRemove[i].context);
            this.GameObjects.splice(this.GameObjects.indexOf(toRemove[i]), 1);
        }
    }

    Draw() {
        this.Context.clearRect(0, 0, this.Width, this.Height);
        for (let i = 0; i < this.Canvases.length; i++)
            this.Canvases[i].context.clearRect(0, 0, this.Width, this.Height);

        //GameObjects
        for (let i = 0; i < this.GameObjects.length; i++)
            this.GameObjects[i].Draw();

        this.Canvases.forEach(x => this.Context.drawImage(x.canvas, 0, 0));
    }

    SetupCanvas() {
        this.Canvas = document.getElementById("gameArea");
        this.Context = this.Canvas.getContext("2d");
        this.Canvas.width = this.Width;
        this.Canvas.height = this.Height;
    }

    DrawFPS(secondsPassed) {
        const fps = Math.round(1 / secondsPassed);
        this.FPSCounter.textContent = "FPS: " + fps;
    }

}

export { Game };


//AnimationFrameHelper
var requestAnimationFrame = window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame;
var cancelAnimationFrame = window.cancelAnimationFrame ||
    window.webkitCancelRequestAnimationFrame ||
    window.webkitCancelAnimationFrame ||
    window.mozCancelRequestAnimationFrame || window.mozCancelAnimationFrame ||
    window.oCancelRequestAnimationFrame || window.oCancelAnimationFrame ||
    window.msCancelRequestAnimationFrame || window.msCancelAnimationFrame;