import './sensors.less';

import * as ko from 'knockout';
import template from './sensors.html';

import { service as orientationService } from "../../services/deviceOrientationService";
import { service as locationService } from "../../services/locationService";

export class SensorsViewModel {

  public vibrationSupported = ko.observable(false);
  public locationService = locationService;
  public deviceOrientationService = orientationService;
  public deviceOrientationButtonText = ko.pureComputed(() => {
    return this.deviceOrientationService.readSensors() ? "Disable" : "Enable";
  });

  constructor(params: any) {
    this.initVibration();
  }

  private initVibration() {
    this.vibrationSupported('vibrate' in navigator);
  }

  public doVibrate(type: string) {
    switch (type) {
      case "short":
        navigator.vibrate([200, 100, 200]);
        break;
      case "long":
        navigator.vibrate([500, 100, 500, 500, 100, 500, 500, 100, 500]);
        break;
      case "stop":
        navigator.vibrate([]);
        break;
      default:
        return;
    }
  }

  public toggleOrientationRead() {
    this.deviceOrientationService.readSensors(!this.deviceOrientationService.readSensors());
  }

  dispose() {
  }
}

ko.components.register("sensors", { viewModel: SensorsViewModel, template: template })

