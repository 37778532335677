import * as ko from "knockout";

class ConnectionChecker {
    private state = ko.observable(false);
    public getState = ko.computed(() => {
        const state = this.state();
        return state ? "Online" : "Offline";
    });

    constructor() {
        window.addEventListener('online', () => this.handleConnection());
        window.addEventListener('offline', () => this.handleConnection());
        this.handleConnection();
    }

    private handleConnection() {
        this.state(navigator.onLine);
    }
}

export const service = new ConnectionChecker();