
import '@babel/polyfill'; // Promise polyfill and others
import 'unfetch/polyfill'; // Fetch polyfill
import 'knockout-punches'; // Support for handlebars syntax
import "./index.html.ejs";

import * as ko from 'knockout';

import "./components";
import "./page-components";

class AppViewModel {
  constructor() {
  }
}

export { swReg } from "./sw-handler";

(<any>ko).punches.enableAll(); // Enable support for handlebars syntax
(<any>ko).punches.textFilter.enableForBinding('data-bind');

ko.applyBindings(new AppViewModel(), document.getElementById('app'));