import './home.less';

import * as ko from 'knockout';
import template from './home.html';
import { service as connectionService } from "../../services/onlineOfflineService";


export class HomeViewModel {

  public promptEvent: any;
  public activeTabIndex = ko.observable(-1);
  public connectionService = connectionService;
  public isInstalled = ko.observable(false);

  public cardList = ko.pureComputed(() => [
    { id: 'map', title: 'Map', text: 'Map and POIs', action: () => this.activeTabIndex(0), active: this.activeTabIndex() == 0 },
    { id: 'shooter', title: 'Shooter', text: 'Canvas2D Animation', action: () => this.activeTabIndex(1), active: this.activeTabIndex() == 1 },
    { id: 'jsonloader', title: 'JSON Loader', text: 'Load JSON', action: () => this.activeTabIndex(2), active: this.activeTabIndex() == 2 },
    { id: 'sensors', title: 'Sensors', text: 'Sensors', action: () => this.activeTabIndex(3), active: this.activeTabIndex() == 3 },
    { id: 'notif', title: 'Notification', text: 'Schedule Notifications', action: () => this.activeTabIndex(4), active: this.activeTabIndex() == 4 },
  ]);

  constructor() {
    this.loadLastKnownTab();
    this.registerInstall();
    this.registerTabChange();
    this.isInstalled(window.matchMedia('(display-mode: standalone)').matches);
  }

  private loadLastKnownTab() {
    const tab = localStorage.getItem('lastActiveTab');
    if (!tab)
      this.activeTabIndex(0);
    else
      this.activeTabIndex(+tab);
  }

  private registerTabChange() {
    this.activeTabIndex.subscribe(() => {
      localStorage.setItem('lastActiveTab', this.activeTabIndex().toString());
    })
  }

  private registerInstall() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.promptEvent = e;
    });
  }

  private install() {
    if (!this.promptEvent) return;

    this.promptEvent.prompt();
    this.promptEvent.userChoice
      .then((choice: any) => {
        if (choice.outcome === 'accepted') {
          console.log('User accepted');
        } else {
          console.log('User dismissed');
        }
      })
  }

  dispose() {
  }
}

ko.components.register("home", { viewModel: HomeViewModel, template: template })