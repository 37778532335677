import './get-json-data.less';

import * as ko from 'knockout';
import template from './get-json-data.html';
import { WorkerType, WorkerWrapper } from '../../services/workers/workerWrapper';
import { IFetchWorkerResult } from '../../services/workers/fetch.worker';

export class GetJsonDataViewModel {

  public dataUrl = ko.observable();
  public jsonResult = ko.observable();

  constructor(params: any) {
  }

  public async testFetch(url: string) {
    this.dataUrl(url);
    void this.fetchJson();
  }

  public async fetchJson() {
    try {
      const result: IFetchWorkerResult = await WorkerWrapper.postToWorker(WorkerType.fetch, { url: this.dataUrl() });
      this.jsonResult(JSON.stringify(result));
    } catch (e) {
      alert("Unable to fetch: " + JSON.stringify(e));
    }
  }

  dispose() {
  }
}

ko.components.register("get-json-data", { viewModel: GetJsonDataViewModel, template: template })

